<template>
  <v-card>
    <v-card-title class="align-start">
      <v-row>
        <v-col>
          <div class="d-flex">
            <span class="font-weight-semibold text-h6 text--white">Label</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ms-1" v-bind="attrs" v-on="on">
                  {{ icons.mdiInformation }}
                </v-icon>
              </template>
              <div>
                <span>
                  {{ $t('label.emptyLabel1') }}
                </span>
              </div>
              <div>
                <span>
                  {{ $t('label.emptyLabel2') }}
                </span>
              </div>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" md="3">
          <v-select
            v-if="selectedTableData.length > 0"
            v-model="selectedAction"
            label="Actions"
            single-line
            outlined
            item-text="label"
            dense
            calculate-widths
            :items="actions"
            hide-details
            :disabled="Boolean(!selectedTableData.length)"
            style="width: 200px"
            @change="selectAction"
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="auto">
          <v-btn block color="primary" @click="openConfig">
            <v-icon dark left>
              {{ icons.mdiCog }}
            </v-icon>
            {{ $t('config') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="selectedlabel" cols="12" md="auto">
          <v-btn dense block color="primary" @click="filterLabel('', '')">
            <v-icon dark left>
              {{ icons.mdiAutorenew }}
            </v-icon>
            {{ `${$t('clear')} Filter` }}
          </v-btn>
        </v-col>
        <div v-for="(data, index) of labelData" :key="index">
          <v-col cols="12" md="auto">
            <v-btn dense outlined color="blue-grey" @click="filterLabel(data.id, data.label)">
              <v-icon v-if="selectedlabel == data.id" dark class="mr-2">
                {{ icons.mdiCheck }}
              </v-icon>
              <v-icon dark left :color="data.color">
                {{ icons.mdiLabel }}
              </v-icon>
              {{ data.label }}
            </v-btn>
            <!-- <v-chip
              outlined
              label
              :color="data.id == 2 ? 'primary' : data.color"
              :class="`pa-3 cursor-pointer`"
              @click="filterLabel(data.id, data.label)"
            >
              <v-icon
                v-if="selectedlabel == data.id"
                dark
                class="mr-2"
              >
                {{ icons.mdiCheck }}
              </v-icon>
              <v-icon
                dark
                left
              >
                {{ icons.mdiLabel }}
              </v-icon>
              {{ data.label }}
            </v-chip>
          </v-col> -->
          </v-col>
        </div>
      </v-row>
    </v-card-text>
    <!-- table -->
    <v-data-table
      v-model="selectedTableData"
      item-key="id"
      :headers="columnsLabel"
      :items="dataLabel"
      :options.sync="options"
      :loading="loading"
      show-select
      must-sort
      class="text-no-wrap"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel'),
      }"
    >
      <template slot="no-data">
        <div class="mx-auto mb-10">
          <v-img class="mx-auto" width="256" length="256" src="@/assets/images/no label.png"></v-img>
          <span class="text-h6 text--white font-weight-bold">{{ $t('label.emptyList') }}</span>
        </div>

        <!-- <v-row
          align="center"
          class="py-16 padding-hero"
        >
          <v-col
            class="text-center"
            lg="6"
            md="6"
            sm="12"
            cols="12"
          >
            <div class="text-start hero-landing">
              <h1 class="hero">
                {{ $t('Label') }} {{ $t('notFound') }}
              </h1>
              <div>
                <span>
                  {{ $t('label.emptyLabel1') }}
                </span>
              </div>
              <div>
                <span>
                  {{ $t('label.emptyLabel2') }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col
            lg="6"
            md="6"
            sm="12"
            cols="12"
            class="hide-lottie"
          >
            <div
              class="mx-auto"
              style="width: 50%;"
            >
              <lottie-player
                src="https://assets8.lottiefiles.com/packages/lf20_IwKi9V.json"
                background="transparent"
                speed="1"
                class="mx-auto"
                loop
                autoplay
              ></lottie-player>
            </div>
          </v-col>
        </v-row> -->
      </template>

      <template v-slot:item.action="{ item, index }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" icon v-on="on" @click.stop="deleteLabel(item, index)">
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ `${$t('delete')} ${$t('label')}` }}</span>
        </v-tooltip>

        <!-- <v-btn
          icon
          @click.stop="deleteLabel(item, index)"
        >
          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
        </v-btn> -->
      </template>
      <template #[`item.label`]="{ item }">
        <v-icon dark :style="{ color: item.label.color }">
          {{ icons.mdiLabel }}
        </v-icon>
        {{ getLabelName(item.label.id) }}
      </template>
      <template #[`item.phone_number_show`]="{ item }">
        <div class="d-flex align-center" style="cursor: pointer" @click="gotoInbox(item)">
          <v-avatar size="40">
            <v-img :src="getProfPic(item.profile_picture, item)" height="40" width="40"> </v-img>
          </v-avatar>
          <div class="d-flex flex-column ms-3">
            <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.name }}</span>
            <span v-if="item.phone_number_show" class="text-xs">{{
              item.phone_number_show.length > 16 ? 'Group Chat' : item.phone_number_show
            }}</span>
          </div>
        </div>
      </template>
      <template #[`item.last_message`]="{ item }">
        {{ item.last_message ? $moment(item.last_message).format('DD-MM-YYYY HH:mm:ss') : '' }}
      </template>
      <template #[`item.chat`]="{ item }">
        <v-icon v-if="item.chat" @click="viewChat(item.chat)">
          {{ icons.mdiEyeOutline }}
        </v-icon>
      </template>
      <!-- <template #[`item.notes`]="{ item }">
        <v-icon
          v-if="item.notes"
          @click="viewNotes(item.notes)"
        >
          {{ icons.mdiEyeOutline }}
        </v-icon>
      </template> -->
    </v-data-table>
    <v-dialog v-model="show5History" width="600px">
      <v-card>
        <v-card-text class="font-weight-bold">
          {{ $t('Message') }}
        </v-card-text>
        <v-card-text>
          <span style="white-space: pre-wrap">{{ showChat }}</span>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-dialog
      v-model="showNotes"
      persistent
      max-width="960px"
    >
      <v-card>
        <v-card-text>
          <v-card-title> Show Notes </v-card-title>
        </v-card-text>
        <v-card-text>
          <div
            v-for="note in notesData"
            :key="note.id"
            class="mt-3"
          >
            <div class="card-note">
              <div style="white-space: pre-wrap">
                <span>{{ note.name }}</span>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col></v-col>
            <v-col
              cols="12"
              lg="3"
              md="3"
            >
            </v-col>
            <v-col
              cols="12"
              lg="3"
              md="3"
            >
              <v-btn
                block
                color="error"
                @click="showNotes = false"
              >
                <v-icon
                  dark
                  left
                >
                  {{ icons.mdiClose }}
                </v-icon>
                {{ $t('close') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- dialog label -->
    <dialogChangeLabel ref="dialogChangeLabel"></dialogChangeLabel>

    <!-- dialog label -->
    <dialogLabel ref="dialogLabel"></dialogLabel>

    <!-- dialog confirmation -->
    <confirm ref="confirm"></confirm>
  </v-card>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
// import custom
import {
  mdiAutorenew,
  mdiCheck,
  mdiClose,
  mdiCog,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiInformation,
  mdiLabel,
} from '@mdi/js'
import { TimeFormater } from '../../helpers'
import pouchDbMixin from '../../mixins/pouchDbMixin'
import confirm from '../component/Confirm.vue'
import dialogChangeLabel from '../component/DialogChangeLabel.vue'
import dialogLabel from '../component/DialogLabel.vue'

export default {
  name: 'Label',
  components: {
    confirm,
    dialogLabel,
    dialogChangeLabel,
  },
  mixins: [pouchDbMixin],
  data() {
    return {
      // SETTING
      dbSettings: null,
      dbRemoteSettings: null,
      tableColumns: [],
      columnsSetting: null,
      labelSetting: null,
      labelData: [],
      labelValue: [],

      // CONTACT
      dbContacts: null,
      dbRemoteContacts: null,
      contactListTable: [],

      activeFilter: null,

      // TABLE
      selectedlabel: '',
      selectedTableData: [],
      loading: false,
      selectedAction: '',
      actions: [
        { label: this.$t('delete'), value: 'delete' },

        // { label: this.$t('Broadcast'), value: 'broadcast' },
        { label: this.$t('changeLabel'), value: 'changeLabel' },
      ],
      columnsLabel: [
        { text: '', value: 'action', sortable: false },
        { text: this.$t('Label'), value: 'label', sortable: false },
        { text: this.$t('EOrdering.phoneNumber'), value: 'phone_number_show', sortable: false },

        // { text: 'Name', value: 'name' },
        { text: this.$t('lastMessage'), value: 'last_message' },
        { text: this.$t('Message'), value: 'chat', sortable: false },
      ],
      options: {
        sortBy: ['last_message'],
        sortDesc: [true],
      },
      icons: {
        mdiCog,
        mdiLabel,
        mdiAutorenew,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiClose,
        mdiCheck,
        mdiInformation,
      },
      show5History: false,

      showChat: '',

      showNotes: false,
      notesData: [],
    }
  },
  computed: {
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    dataLabel() {
      //console.log(this.labelValue, 'DARI LABEL JUGA')
      return this.labelValue
        .map(label => {
          const rObj = {}
          //console.log(label, 'DARI LABEL')

          let last_message = null
          if (label.contact.last_message) {
            last_message = this.$moment.unix(label.contact.last_message / 1000).format()
          } else if (label.chat && label.chat.original_message) {
            if (label.chat.original_message.messageTimestamp.low) {
              last_message = this.$moment.unix(label.chat.original_message.messageTimestamp.low).format()
            } else {
              last_message = this.$moment.unix(label.chat.original_message.messageTimestamp).format()
            }
          } else if (label.contact.lastMessage) {
            if (label.contact.lastMessage.original_message.messageTimestamp.low) {
              last_message = this.$moment.unix(label.contact.lastMessage.original_message.messageTimestamp.low).format()
            } else {
              last_message = this.$moment.unix(label.contact.lastMessage.original_message.messageTimestamp).format()
            }
          } else {
            last_message = null
          }

          rObj.id = label.id
          rObj.label = label.label
          rObj.label_name = label.labelName
          rObj.phone_number = label.contact ? label.contact.phone_number : ''
          rObj.phone_number_show = label.contact ? label.contact.phone_number_show : ''
          rObj.last_message = last_message
          rObj.name = label.contact ? label.contact.name : ''
          rObj.chat = label.chat ? label.chat.content : ''
          rObj.profile_picture = label.contact ? label.contact.avatar : ''
          rObj.roomId = label?.contact?.roomId ? label.contact.roomId : ''

          return rObj
        })
        .filter(x => (this.selectedlabel ? x.label.id === this.selectedlabel : x))
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
  watch: {
    selectedTableData() {
      if (this.selectedTableData.length === 0) {
        this.selectedAction = ''
      }
    },
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
  },
  async mounted() {
    this.loading = true

    await this.getLabelValue()

    // if (this.allSettings.length === 0) {
    //   this.loading = this.allSettings.length === 0
    // } else {
    //   await this.loadSetting()
    // }

    // await this.connectSettings()
    // await this.loadSetting(true)

    // this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, async payload => {
    //   await this.loadSetting(true)
    // })

    this.loading = false
  },
  methods: {
    async getLabelValue() {
      const labelsPg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'label',
      })
      //console.log(labelsPg, 'GET LABEL')

      if (labelsPg.status) {
        this.labelData = labelsPg.data.value
      }
      const labelsValuePg = await this.$store.dispatch('global/getSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'label_value',
      })

      //console.log(labelsValuePg, 'GET LABEL VALUE')

      if (labelsValuePg.status) {
        this.labelValue = labelsValuePg.data.value
      }
    },
    getLabelName(id) {
      const obj = this.labelData.find(x => x.id === id)
      if (obj) return obj.label

      return ''
    },

    openConfig() {
      this.$refs.dialogLabel.open(this.labelData).then(async confirm => {
        if (confirm.status) {
          await this.getLabelValue()
        }
      })
    },

    async loadSetting(fromServer = false) {
      if (fromServer) {
        await this.loadSettings()
      }
      const res = await this.loadSettingLabel(this.allSettings)
      this.columnsSetting = res.columnsSetting
      this.tableColumns = res.tableColumns
      this.labelData = res.labelData
      this.labelSetting = res.labelSetting

      // this.labelValue = res.labelValue
    },
    dateFormater(dt) {
      return TimeFormater.dateFormater(dt)
    },
    getProfPic(img, item) {
      if (img) return img

      return `${process.env.VUE_APP_WA_API_URL}ppic/${item.phone_number}@whatsapp.net/${item.phone_number}/a.jpg`
    },
    selectAction() {
      if (this.selectedAction === 'delete') {
        const self = this
        this.$refs.confirm
          .open(
            this.$t('delete'),
            `${this.$t('deleteConfirmation')} ${this.selectedTableData.length} ${this.$t('Label')}?`,
            { color: 'red' },
            this.$t('delete'),
          )
          .then(async conf => {
            if (conf) {
              this.selectedTableData.forEach(data => {
                const idx = this.labelValue.findIndex(x => x.id === data.id)
                if (idx > -1) {
                  self.labelValue.splice(idx, 1)
                }
              })

              const res = await this.$store.dispatch('global/updateSettingsByKey', {
                sub_id: this.user.sub_id,
                token: this.user.token,
                key: 'label_value',
                value: this.labelValue,
              })
              if (res.status) {
                this.getLabelValue()
              }

              // Update filter label in Chat
              this.$store.commit('inbox/setLabelValue', this.labelValue)

              // self.updateSettings(self.labelSetting, async success => {
              //   if (success) {
              //     self.loading = true
              //     await self.loadSetting(true)
              //     self.loading = false
              //   }
              // })
              this.selectedAction = ''
            } else {
              this.selectedAction = ''
            }
          })
      } else if (this.selectedAction === 'changeLabel') {
        this.$refs.dialogChangeLabel.open(this.labelData, this.selectedTableData, this.labelValue).then(res => {
          if (res.status) {
            this.selectedTableData = []
          }
          this.selectedAction = ''
        })
      }
    },
    deleteLabel(data, index) {
      const self = this
      this.$refs.confirm
        .open(this.$t('delete'), `${this.$t('deleteConfirmation')} ${data.name} ?`, { color: 'red' }, this.$t('delete'))
        .then(async conf => {
          if (conf) {
            const idx = this.labelValue.findIndex(x => x.id === data.id)
            if (idx > -1) {
              self.labelValue.splice(idx, 1)
            }

            const res = await this.$store.dispatch('global/updateSettingsByKey', {
              sub_id: this.user.sub_id,
              token: this.user.token,
              key: 'label_value',
              value: this.labelValue,
            })
            if (res.status) {
              this.getLabelValue()
            }

            // Update filter label in Chat
            this.$store.commit('inbox/setLabelValue', this.labelValue)

            // self.updateSettings(self.labelSetting)
          } else {
            this.selectedAction = ''
          }
        })
    },
    filterLabel(id, label) {
      this.selectedlabel = id
      this.activeFilter = label
    },
    viewChat(item) {
      this.show5History = true
      this.showChat = item
    },
    viewNotes(notes) {
      this.showNotes = true
      this.notesData = notes
    },
    gotoInbox(data) {
      this.$router.push({
        name: 'inbox',
        params: {
          phoneNumber: data.roomId,
        },
      })
    },
  },
}
</script>
<style scoped>
.card-note {
  background-color: #ffffff67;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #adadad;
}

.v-data-table-header__icon {
  opacity: 1;
}

@media only screen and (max-width: 578px) {
  .sm-display {
    display: none;
  }

  .last-served-image {
    display: none;
  }

  .last-served {
    font-size: 12px;
  }

  .phone-number {
    font-size: 12px;
  }
}

@media only screen and (min-width: 578px) {
  .sm-display-mobile {
    display: none;
  }
}
.bottom-action {
  display: none;
}
.padding-hero {
  padding-left: 80px;
  padding-right: 80px;
}
.mobile-tag {
  display: none;
}
/* Mobile */

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .bottom-action {
    display: inherit;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
    width: 200px;
  } */
}
</style>
